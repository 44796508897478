import Image1 from '../../assets/brands/labella/1.jpg'
import Image2 from '../../assets/brands/labella/2.jpg'
import Image3 from '../../assets/brands/labella/3.jpg'
import Image4 from '../../assets/brands/labella/4.jpg'
import Image5 from '../../assets/brands/labella/5.jpg'

const LabellContent = {
  title: "La-Bella",
  paragraph: [
    "La Bella Strings is a leading manufacturer of high-quality guitar, bass, and other strings. Founded in 1640 in Italy, La Bella has a long history of innovation and commitment to quality. They offer a wide range of products, including standard and custom strings for virtually every type of stringed instrument. La Bella's dedication to personalized customer service ensures that musicians of all levels receive the support they need to make beautiful music.",
    "For example,  Willie Nelson , Bryan Adams, and  Frank Anthony “Tony” Iommi of Black Sabbath, Chuck Rainey are just a few of the many high-profile artists who use La Bella Strings.",
  ],
  images:[
    Image1,
    Image2,
    Image3,
    Image4,
    Image5 ,
  ]
};

export default LabellContent;