import Image1 from '../../assets/brands/olinto/1.jpg'
import Image2 from '../../assets/brands/olinto/2.jpg'
import Image3 from '../../assets/brands/olinto/3.jpg'
import Image4 from '../../assets/brands/olinto/4.jpg'
import Image5 from '../../assets/brands/olinto/5.jpg'
import Image6 from '../../assets/brands/olinto/6.jpg'

const OlintoContent = {
  title: "Olinto Bass",
  paragraph: [
    "Olinto Bass by La Bella is a line of boutique bass guitars made by the collaboration of master luthiers Mas Hino and James Carbonetti. Launched in 2016, these basses are a perfect blend of tradition and innovation, featuring classic designs with modern touches.",
    "One of the unique features of the Olinto Bass by La Bella is the use of premium woods for the body and neck, such as ash, alder, and maple, which are carefully selected for their tone and beauty. The basses also feature top-quality hardware, including Hipshot tuners and bridges, and are equipped with La Bella strings.",
    "But what really sets the Olinto Bass by La Bella apart is the attention to detail and the craftsmanship of the luthiers. Mas Hino is a master craftsman with over 40 years of experience, while James Carbonetti is a rising star in the world of guitar and bass building. Together, they bring a wealth of knowledge and skill to the creation of each bass.",
    "The Olinto Bass by La Bella comes in four- and five-string models, with a variety of finish options, including natural, sunburst, and custom colors. Each bass is made to order, allowing for a high level of customization to suit the player's preferences.",
    "The Olinto Bass by La Bella has received rave reviews from musicians and critics alike, with its impeccable craftsmanship, stunning looks, and incredible tone. Whether you're a seasoned professional or a serious hobbyist, the Olinto Bass by La Bella is a great choice for those who demand the best from their instruments include Mitch Friedman and Scott from Scott bass lessons."
  ],
  images:[
    Image1,
    Image2,
    Image3,
    Image4,
    Image5 ,
    Image6
  ]
};

export default OlintoContent;