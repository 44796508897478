const kardian = {
  title: "Kardian",
  paragraph: [
    <>
      At the heart of Kardian Guitar Pedals lies a passionate devotion to the
      magic of effects pedals. Founded by a dedicated enthusiast, Kitada San,
      Kardian emerged as a tribute to the enchanting allure of these sonic
      treasures.
      <br />
      While effects pedals may not always be deemed essential, their absence can
      undoubtedly leave a void of excitement. Much like the uncertainty
      surrounding their necessity, we find ourselves pondering the essential
      role they play in our musical endeavors. Thus, with a touch of paradox,
      &quot;KarDiaN&quot; was christened as our pedal equivalent to the heart.{" "}
      <br />
      &quot;KarDiaN&quot; elevates these seemingly non-essential pedals to the
      status of our musical lifeblood, enriching our sonic experiences to the
      core. 
      <br />
      From the serene landscapes of Shiga Prefecture, Kardian continuously
      crafts masterpiece pedals, catering to players worldwide who hold a
      profound appreciation for the diverse tapestry of sound.
    </>,
  ],
};

export default kardian;
