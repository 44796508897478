const iconicGuitarContent = {
  title: "Iconic Guitars",
  paragraph: [
    <>
    Iconic Guitars is a boutique guitar manufacturing company located in Carlsbad,
California, that specializes in creating custom electric guitars. The company was founded
with the goal of providing musicians with unique, high-quality instruments that offer
exceptional tone, playability, and aesthetics.<br/>
One of the defining features of Iconic Guitars is its focus on customization. The company
offers a range of base models to choose from, each with a distinctive sound and feel.
From there, customers can select from a variety of options, including different pickups,
woods, finishes, and other hardware, to create a guitar that perfectly fits their individual
preferences and playing style.<br/>
Iconic Guitars is also known for its use of innovative technology in guitar building. The
company utilizes advanced computer-aided design (CAD) software and precision
machinery to ensure that each instrument is crafted to exact specifications, resulting in a
level of consistency and accuracy that is difficult to achieve with traditional methods.<br/>
Despite its relatively short history, Iconic Guitars has quickly established itself as a leader
in the custom guitar market. Its instruments are highly sought after by professional
musicians and collectors alike, thanks to their exceptional craftsmanship, innovative
design, and unmatched sound quality.
    </>
  ],
};

export default iconicGuitarContent;