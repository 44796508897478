const DeathByAudio = {
  title: "Death By Audio",
  paragraph: [
    <>
      <b>Introduction </b>:<br/> Death By Audio Pedals is a renowned boutique pedal
      company based in Queens, New York, known for its innovative and
      experimental approach to effects pedals. Founded by Oliver Ackermann,
      Death By Audio has garnered widespread acclaim among musicians for its
      unique sound and uncompromising commitment to creativity. <br />
      <b> History </b>: <br/> The story of Death By Audio Pedals begins with Oliver
      Ackermann&#39;s passion for music and electronics. Ackermann, a musician
      himself, began tinkering with guitar pedals in the early 2000s,
      experimenting with circuitry and exploring the sonic possibilities of
      different components. <br />
      In 2002, Ackermann founded Death By Audio, initially as a DIY recording
      studio and live music venue in the burgeoning Brooklyn music scene. The
      space quickly became a hub for experimental music, attracting artists from
      all over the world to perform and collaborate. <br />
      Inspired by his experiences in the music community, Ackermann began
      developing his own line of effects pedals under the Death By Audio name.
      Drawing on his background in electronics and his love for unconventional
      sounds, Ackermann set out to create pedals that pushed the boundaries of
      traditional effects. <br />
      The first Death By Audio pedals were hand-built in Ackermann&#39;s
      Brooklyn workshop, featuring unique designs and circuitry that reflected
      his experimental ethos. From fuzz and distortion to delay and modulation,
      Death By Audio pedals offered musicians a palette of otherworldly sounds
      and textures. <br />
      As word spread about the innovative designs and exceptional quality of
      Death By Audio pedals, the company gained a devoted following among
      musicians seeking to expand their sonic horizons. Artists ranging from
      indie rockers to avant-garde experimentalists embraced Death By Audio
      pedals for their versatility and character. <br />
      In 2014, Death By Audio relocated its operations to Queens, New York,
      where it continues to design and manufacture pedals to this day. The
      company remains committed to its founding principles of creativity,
      innovation, and sonic exploration, producing a diverse lineup of effects
      pedals that inspire musicians to push the boundaries of their music.{" "}
      <br />
      With its roots deeply entrenched in the New York music scene, Death By
      Audio Pedals embodies the spirit of experimentation and DIY ethos that
      defines the city&#39;s underground music culture. As it continues to
      evolve and innovate, Death By Audio remains at the forefront of the
      boutique pedal industry, inspiring musicians around the world to explore
      new sonic territories.
    </>,
  ],
};

export default DeathByAudio;
