import Image1 from '../../assets/brands/mtd/1.jpg'
import Image2 from '../../assets/brands/mtd/2.jpg'
import Image3 from '../../assets/brands/mtd/3.jpg'

const MTDContent = {
  title: "Michael Tobias Design (MTD) Basses:",
  images:[
    Image1,
    Image2,
    Image3,
  ],
  paragraph: [
    <>
   <b> Introducing Michael Tobias Design (MTD) Basses: Where Artistry Meets Precision</b><br/>
We are delighted to present Michael Tobias Design (MTD), a name that embodies the
fusion of artistry and precision in the world of bass guitar craftsmanship. As a dedicated
distributor in the Chinese musical instrument industry, we are thrilled to bring MTD
basses to our customers.<br/>
<b>About Michael Tobias Design (MTD):</b><br/>
MTD is a renowned brand with a legacy of crafting some of the finest bass guitars
available. Michael Tobias, the founder and master luthier, has built a reputation for
creating instruments that are both musically inspiring and visually striking. With an
unwavering commitment to excellence, MTD has consistently raised the bar in the realm
of bass guitar design.<br/>
<b>The MTD Difference:</b><br/>
MTD basses are celebrated for their exceptional playability, tonal versatility, and
meticulous craftsmanship. Each instrument is a masterpiece, handcrafted to perfection,
with a harmonious blend of aesthetics and functionality. MTD&#39;s unique designs and
meticulous attention to detail make their basses a top choice for musicians seeking the
ultimate in performance and sonic expression.<br/>
<b>What We Offer:</b><br/>
As the official distributor for MTD in China, we are thrilled to offer you a wide range of
MTD basses, known for their superior tone and playability. Whether you&#39;re a professional
bassist or an aspiring musician, MTD basses have something extraordinary to offer.
These instruments are trusted by musicians worldwide for their ability to deliver the
perfect blend of art and precision.
<br/>
Explore our collection of MTD basses and find the instrument that resonates with your
unique style and musical aspirations. From jazz to rock, funk to fusion, MTD basses are
designed to inspire and elevate your musical journey.
<b>Experience the MTD Advantage:</b><br/>
Step into the world of MTD basses and unlock a new level of sonic excellence. Michael
Tobias Design has set the standard for innovation and quality in bass guitar
craftsmanship. We are honored to be your source for MTD basses in China, and we can&#39;t
wait to accompany you on your musical journey with instruments that exemplify the
perfect balance between artistry and precision.
Thank you for choosing us as your distributor for MTD basses. Discover the
extraordinary world of MTD, where your musical aspirations are brought to life in the
form of exceptional bass guitars.
    </>,
  ],
};

export default MTDContent;