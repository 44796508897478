import { Box, Container } from "@mui/system";
import { Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import LabellContent from "../data/brands/labella";
import LaStrapContent from "../data/brands/lastraps";
import MTDContent from "../data/brands/mtd";
import OlintoContent from "../data/brands/olinto";
import ZonbassContent from "../data/brands/zonbass";
import bergantinoAudioContent from "../data/brands/bergantinoAudio";
import CustomCard from "../components/card";
import elrickBassContent from "../data/brands/elrickBass";
import iconicGuitarContent from "../data/brands/iconicGuitars";
import alembicGuitarsContent from "../data/brands/alembicGuitars";
import allevaCoppoloContent from "../data/brands/allevaCoppolo";
import lehGuitarsContent from "../data/brands/leh";

import v2Atelier from "../data/brands/v2-atelier";
import v2Blammo from "../data/brands/v2-blammo";
import v2DeathByAudio from "../data/brands/v2-deathByAudio";
import v2DrScientist from "../data/brands/v2-drScientist";
import v2fodera from "../data/brands/v2-fodera";
import v2IdiotBox from "../data/brands/v2-IdiotBox";
import v2Kardian from "../data/brands/v2-kardian";
import v2Novo from "../data/brands/v2-novo";
import v2Pensa from "../data/brands/v2-pensa";
import v2EarthQuakerDevices from "../data/brands/v2-earthQuakerDevices";

const getContent = (path) => {
  switch (path) {
    case "la-bella":
      return LabellContent;
    case "lk-strap":
      return LaStrapContent
    case "mtd":
      return MTDContent;
    case "olinto":
      return OlintoContent;
    case "zon-bass":
      return ZonbassContent;
    case "bergantino-audio":
      return bergantinoAudioContent;
    case "elrick-bass":
      return elrickBassContent;
    case "iconic-guitars":
      return iconicGuitarContent;
    case "alembic-guitars":
      return alembicGuitarsContent;
    case "the-alleva-coppolo-bass":
      return allevaCoppoloContent;
    case "leh-guitars":
      return lehGuitarsContent;
    case "fodera-guitars":
      return v2fodera;
    case "death-by-audio":
      return v2DeathByAudio;
    case "blammo-electronics":
      return v2Blammo;
    case "atelier-z":
      return v2Atelier;
    case "dr-scientist":
      return v2DrScientist;
    case "pensa":
      return v2Pensa;
    case "idiotbox-effects":
      return v2IdiotBox;
    case "novo":
      return v2Novo;
    case "kardian":
      return v2Kardian;
    case "earthquaker-devices":
      return v2EarthQuakerDevices;
    default:
      return { title: "Page Not Found" };
  }
};
export const Brand = () => {
  const { path } = useParams();
  const content = getContent(path);
  return (
    <Container sx={{ marginTop: 10,marginBottom:10 }}>
      <Typography
        variant="h4"
        fontWeight="800"
        textAlign="center"
        color="#2596be"
        fontFamily="Carter One"
        style={{ textShadow: "1px 1px 2px #eeeeee" }}
      >
        {content?.title}
      </Typography>
      <Box sx={{ marginTop: 3 }}></Box>
      {content.paragraph?.map((text,index) => (
        <Typography key={index} lineHeight={2.5} variant="body1">
          {text}
        </Typography>
      ))}
       <Grid justifyContent={'center'} marginTop={5} container spacing={1}>
        {content.images?.map((image,index) => (
          <Grid key={index} item>
            <CustomCard image={image} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Brand;
