import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link } from "react-router-dom";
import labella from "../assets/home/labella.png";
import lk from "../assets/home/lk.png";
import mtd from "../assets/home/mtd.png";
import olinto from "../assets/home/olinto.png";
import zon from "../assets/home/zon.png";
import bergantino from "../assets/home/bergantino.jpg";
import elrickbass from "../assets/home/elrickbass.webp";
import iconicGuitars from "../assets/home/iconic-guitars.jpeg";
import alembicGuitars from "../assets/home/alembic-guitars.png";
import allevaCoppolo from "../assets/home/alleva-coppolo.jpg";
import fodera from "../assets/home/fodera.jpg";
import death_by_audio from "../assets/home/death-by-audio.jpg";
import blammo from "../assets/home/blammo.png";
import atelier from "../assets/home/atelier.png";
import dr_scientist from "../assets/home/dr-scientist.png";
import idotbox from "../assets/home/idotbox.png";
import novo from "../assets/home/novo.png";
import kardian from "../assets/home/kardian.png";
import leh from "../assets/home/leh-logo.png";
import pensa from "../assets/home/pensa.png";
import earthQuakerDevices from "../assets/home/earthQuaker-devices.jpg";


const BRANDS = [
  {
    img: labella,
    link: "/brand/la-bella",
  },
  { img: lk, link: "/brand/lk-strap",name:'lk-strap' },
  { img: mtd, link: "/brand/mtd",name:'mtd' },
  { img: olinto, link: "/brand/olinto",name:'olinto' },
  { img: zon, link: "/brand/zon-bass" ,name:'zon-bass'},
  { img: bergantino, link: "/brand/bergantino-audio" ,name:'bergantino-audio'},
  { img: elrickbass, link: "/brand/elrick-bass" ,name:'elrick-bass'},
  { img: iconicGuitars, link: "/brand/iconic-guitars" ,name:'iconic-guitars'},
  { img: alembicGuitars, link: "/brand/alembic-guitars" ,name:'alembic-guitars'},
  { img: allevaCoppolo, link: "/brand/the-alleva-coppolo-bass" ,name:'the-alleva-coppolo-bass'},
  { img: leh, link: "/brand/leh-guitars" ,name:'leh-guitars'},
  { img: fodera, link: "/brand/fodera-guitars" ,name:'fodera'},
  { img: death_by_audio, link: "/brand/death-by-audio" ,name:'death-by-audio'},
  { img: blammo, link: "/brand/blammo-electronics" ,name:'blammo'},
  { img: atelier, link: "/brand/atelier-z" ,name:'atelier'},
  { img: dr_scientist, link: "/brand/dr-scientist" ,name:'dr-scientist'},
  { img: pensa, link: "/brand/pensa" ,name:'pensa'},
  { img: idotbox, link: "/brand/idiotbox-effects" ,name:'idiotbox-effects'},
  { img: novo, link: "/brand/novo" ,name:'novo'},
  { img: kardian, link: "/brand/kardian" ,name:'kardian'},
  { img: earthQuakerDevices, link: "/brand/earthquaker-devices" ,name:'earthquaker-devices'},
];
export const Home = () => {
  const [bg] = useState(
    "https://images.squarespace-cdn.com/content/v1/55873fffe4b0e842044c4c9a/1550759195699-B02TXKRS1VKSKG4GN493/AMP_BG_BASS.jpg?format=2500w"
  );
  return (
    <Box
      minHeight={"100vh"}
      width={"100vw"}
      sx={{
        width: "100%",
        background: ` linear-gradient(0deg, #120e0e34, #1512121f), url(${bg})`,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: -5,
        backgroundSize: "cover",
        padding:5
      }}
    >
      <Typography
        fontWeight={800}
        textTransform="uppercase"
        fontFamily="Carter One"
        style={{ textShadow: "1px 1px 2px #fff" }}
        color="#2596be"
        variant="h2"
        textAlign={'center'}
        marginTop={'5%'}
      >
        Welcome to TYT Music
      </Typography>
      <Typography color="#fff" textAlign={'center'} fontSize='1.4rem' marginTop={2} variant="h5">
      We are proud to be distributors of the following brands in China.
      </Typography>

      <Grid marginTop={{xs:5,sm:10}} container justifyContent={"center"} spacing={1}>
        {BRANDS.map((obj, index) => (
          <Grid sx={{mx:0.9}} key={index} item xs="auto">
            <Link title={obj.name} to={obj.link}>
            <Box
             component={'img'}
              style={{
                boxShadow: "0px 0px 5px #fff",
                background: "#fff",
                borderRadius: 5,
              }}
              sx={{
                height: {xs:120,sm:120},
                width: {xs:120,sm:120},
              }}
              // height={120}
              // width={120}
              src={obj.img}
              alt={obj.name}
            />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default Home;
