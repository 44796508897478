const earthQuakerDevices = {
  title: "EarthQuaker Devices",
  paragraph: [
    <>
      In the realm of musical expression, EarthQuaker Devices stands as a beacon
      of innovation and sonic exploration. Born from a passion for pushing the
      boundaries of traditional sound, EarthQuaker Devices has cemented its
      position as a premier manufacturer of boutique guitar effects pedals.
      <br />
      With a commitment to craftsmanship and a penchant for the unconventional,
      EarthQuaker Devices has carved out a niche for itself in the saturated
      world of guitar effects. Each pedal is meticulously crafted by hand in
      their Akron, Ohio workshop, ensuring unparalleled quality and attention to
      detail.
      <br />
      What sets EarthQuaker Devices apart is not just their dedication to
      superior construction, but their relentless pursuit of sonic excellence.
      From ethereal reverbs to gritty distortions, each pedal is designed to
      inspire creativity and elevate your playing to new heights.
      <br />
      Whether you&#39;re a seasoned professional or a bedroom enthusiast,
      EarthQuaker Devices offers a diverse range of effects to suit every style
      and preference. From the haunting echoes of the Avalanche Run to the
      ferocious roar of the Hoof Reaper, there&#39;s a pedal waiting to unlock
      your musical potential.
      <br />
      Join the ranks of legendary musicians who trust EarthQuaker Devices to
      shape their sound. Experience the difference for yourself and embark on a
      sonic journey unlike any other with EarthQuaker Devices guitar effects.
      <br />
    </>,
  ],
};

export default earthQuakerDevices;
