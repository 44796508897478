const novo = {
  title: "Novo",
  paragraph: [
    <>
      Novo Guitars is a boutique guitar company based in Nashville, Tennessee,
      known for its exquisite craftsmanship and unique designs. Founded by
      Dennis Fano, a respected luthier with a passion for vintage guitars and
      innovative craftsmanship, Novo Guitars has quickly become a prominent name
      in the world of boutique instrument making.
      <br />
      The history of Novo Guitars dates back to 2015 when Dennis Fano decided to
      establish his own company after gaining significant experience in guitar
      building and design. Fano&#39;s background includes working for renowned
      guitar brands such as Fano Guitars and Rat Guitars, where he honed his
      skills and developed a deep appreciation for vintage instruments.
      <br />
      With Novo Guitars, Fano set out to combine his love for vintage guitar
      designs with modern construction techniques and materials. The result is a
      lineup of instruments that offer a perfect blend of classic aesthetics and
      contemporary performance.
      <br />
      Novo Guitars gained attention for its meticulous attention to detail and
      commitment to quality. Each instrument is handcrafted in the company&#39;s
      Nashville workshop by a team of skilled artisans, ensuring that every
      guitar meets the highest standards of craftsmanship and playability.{" "}
      <br />
      One of the defining features of Novo Guitars is its focus on
      customization. While the company offers a range of standard models,
      customers have the opportunity to work closely with Novo&#39;s team to
      create bespoke instruments tailored to their preferences. This level of
      customization has made Novo Guitars a top choice for professional
      musicians seeking unique and personalized instruments. 
      <br />
      In addition to its custom offerings, Novo Guitars also produces limited
      edition runs of guitars, often featuring unique finishes and
      specifications. These limited runs further showcase the company&#39;s
      commitment to innovation and creativity.
      <br />
      Since its founding, Novo Guitars has garnered praise from musicians and
      critics alike for its exceptional craftsmanship and innovative designs.
      The company&#39;s instruments have found their way into the hands of many
      notable artists, further solidifying Novo&#39;s reputation as a leading
      name in the world of boutique guitar making.
      <br />
      With its dedication to quality, craftsmanship, and innovation, Novo
      Guitars continues to push the boundaries of what is possible in guitar
      design and construction. As the company looks to the future, it remains
      committed to its core values while continuing to innovate and inspire
      musicians around the world.
    </>,
  ],
};

export default novo;
