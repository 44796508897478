import { Typography } from "@mui/material";

const elrickBassContent = {
  title: "Elrick Bass",
  paragraph: [
    <>
    <b>Elrick Bass</b>: Masterful Craftsmanship, Innovative Designs, and Uncompromising Quality
When it comes to bass guitars, Elrick Bass stands out for its impeccable craftsmanship,
innovative designs, and uncompromising commitment to quality. Each Elrick instrument
is meticulously handcrafted by a team of highly skilled artisans, using only the finest
tonewoods and materials. From the selection of premium tonewoods to the precise
shaping of the neck and body, every detail is carefully considered to ensure optimal
playability and resonance.<br/>
What sets Elrick Bass apart is its dedication to innovation. From the groundbreaking &quot;e-
volution&quot; models with their ergonomic designs to the versatile hybrid fretted/fretless
basses, Elrick Bass continuously pushes the boundaries of instrument design. Their
instruments not only sound exceptional but also offer unparalleled comfort and
playability, allowing musicians to express their creativity effortlessly.<br/>
Endorsed by acclaimed bassists worldwide, Elrick Bass has gained a reputation for
delivering extraordinary tone and performance. Renowned artists such as Mark Michell
(Scale the Summit) and Wojtek Pilichowski have chosen Elrick Bass as their instrument
of choice, praising its rich, articulate sound and exceptional build quality.<br/>
With its exceptional craftsmanship, innovative designs, and the endorsement of esteemed
musicians, Elrick Bass has become a symbol of excellence in the music industry.
Whether you&#39;re a professional bassist searching for a reliable and versatile instrument or
an enthusiast looking for a piece of musical artistry, an Elrick Bass is sure to exceed your
expectations and ignite your passion for music.
</>
  ],
};

export default elrickBassContent;