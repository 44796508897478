const atelier = {
  title: "Atelier Z",
  paragraph: [
    <>
      Atelier Z Guitar Works is a Japanese company renowned for crafting
      high-quality bass guitars. Founded in 1988 by Kaz Goto, Atelier Z quickly
      gained recognition for its dedication to precision craftsmanship and
      innovative design.
      <br />
      Kaz Goto&#39;s passion for bass guitars and his background in woodworking
      laid the foundation for the company&#39;s success. With a commitment to
      creating instruments that met the demands of professional musicians,
      Atelier Z set out to produce bass guitars that offered exceptional tone,
      playability, and aesthetics.
      <br />
      From its inception, Atelier Z focused on custom bass guitars, allowing
      musicians to collaborate closely with the company to design instruments
      tailored to their specific preferences and playing styles. This dedication
      to customization earned Atelier Z a loyal following among bassists who
      appreciated the company&#39;s attention to detail and commitment to
      quality.
      <br />
      As word spread about the exceptional quality of Atelier Z basses, the
      company&#39;s reputation continued to grow both domestically in Japan and
      internationally. Renowned bassists such as Tetsuo Sakurai, Will Lee, and
      Reggie Washington became avid users of Atelier Z instruments, further
      solidifying the brand&#39;s status as a top-tier bass guitar manufacturer.
      <br />
      In addition to its custom instruments, Atelier Z also offers a range of
      standard models designed to provide exceptional performance and value.
      These instruments incorporate many of the same features and craftsmanship
      found in Atelier Z&#39;s custom basses, making them popular choices among
      bassists of all levels.
      <br />
      Over the years, Atelier Z has continued to innovate and refine its
      designs, incorporating new technologies and materials to further enhance
      the performance and versatility of its instruments. Despite its growth and
      success, Atelier Z remains committed to its founding principles of
      craftsmanship, quality, and customer satisfaction.
      <br />
      Today, Atelier Z Guitar Works stands as a respected leader in the world of
      bass guitar manufacturing, representing the rich tradition of Japanese
      craftsmanship and innovation. With its unwavering dedication to
      excellence, Atelier Z continues to set the standard for bass guitars
      worldwide, earning the admiration and respect of musicians everywhere.
    </>,
  ],
};

export default atelier;
