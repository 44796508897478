const fodera={
    title:'Fodera',
    paragraph:[
        <>
        Fodera Guitars is a renowned custom guitar manufacturer based in Brooklyn, New York
        City. The company was founded in 1983 by Vinny Fodera and Joey Lauricella, both of
        whom were passionate about crafting high-quality instruments. Fodera Guitars quickly
        gained recognition for its innovative designs, impeccable craftsmanship, and commitment
        to producing instruments tailored to the needs of professional musicians. <br/>

        Vinny Fodera&#39;s background in woodworking and Joey Lauricella&#39;s expertise in
        electronics provided the perfect foundation for the company&#39;s success. Together, they set
        out to create instruments that not only sounded exceptional but also felt comfortable and
        responsive to the player&#39;s touch. <br/>

        In the early years, Fodera Guitars primarily focused on crafting custom bass guitars,
        catering to the demands of professional bassists who sought instruments with superior
        tone, playability, and aesthetics. The company&#39;s dedication to customization allowed
        musicians to collaborate closely with Fodera&#39;s team to design instruments that matched
        their unique preferences and playing styles.<br/>

        As word spread about the exceptional quality of Fodera instruments, the company&#39;s
        reputation continued to grow within the music industry. Renowned bassists such as
        Anthony Jackson, Victor Wooten, and Matt Garrison became loyal advocates of Fodera
        Guitars, further solidifying its status as a top-tier instrument manufacturer.<br/>

        Over the years, Fodera Guitars expanded its product line to include electric guitars,
        further showcasing its versatility and commitment to innovation. Each instrument
        produced by Fodera is meticulously handcrafted by skilled artisans, using only the finest
        materials and components available.<br/>

        In addition to its custom instruments, Fodera Guitars also offers a range of standard
        models designed to provide exceptional quality and performance at more accessible price
        points. These instruments retain the same level of craftsmanship and attention to detail
        that have become synonymous with the Fodera name.<br/>

        Today, Fodera Guitars continues to thrive as a leading manufacturer of custom guitars
        and basses, serving the needs of professional musicians around the world. With its
        unwavering dedication to quality and innovation, Fodera remains a true icon in the world
        of instrument craftsmanship, proudly representing the rich tradition of artisanal guitar
        making in the heart of New York City.
        </>
    ]
}

export default fodera;