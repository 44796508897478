import {Outlet} from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/fotter';
// @mui
import { styled } from "@mui/material/styles";
import ScrollToTop from '../helper/ScrollToTop';

function RootLayout(props) {
  const Main = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop:80,

  }));

  
  return (
    <>
      <ScrollToTop/>
      <Navbar/>
      <Main>
        <Outlet />
      </Main>
      <Footer/>
    </>
  );
}

export default RootLayout;
