import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/logo.png";

const drawerWidth = 240;
const Brands = [
  { name: "La Bella", link: "/brand/la-bella" },
  { name: "LK Strap", link: "/brand/lk-strap" },
  { name: "MTD Bass", link: "/brand/mtd" },
  { name: "Olinto", link: "/brand/olinto" },
  { name: "Zon Bass", link: "/brand/zon-bass" },
  { name: "Bergantino Audio", link: "/brand/bergantino-audio" },
  { name: "Elrick Bass", link: "/brand/elrick-bass" },
  { name: "Iconic Guitars", link: "/brand/iconic-guitars" },
  { name: "Alembic Guitars", link: "/brand/alembic-guitars" },
  { name: "The Alleva Coppolo Bass", link: "/brand/the-alleva-coppolo-bass" },
  { name: "Leh Guitars", link: "/brand/leh-guitars" },
  { name: "Fodera Guitars", link: "/brand/fodera-guitars" },
  { name: "Death By Audio", link: "/brand/death-by-audio" },
  { name: "Blammo Electronics", link: "/brand/blammo-electronics" },
  { name: "Atelier Z", link: "/brand/atelier-z" },
  { name: "Dr. Scientist", link: "/brand/dr-scientist" },
  { name: "Pensa", link: "/brand/pensa" },
  { name: "IdiotBox Effects", link: "/brand/idiotbox-effects" },
  { name: "Novo", link: "/brand/novo" },
  { name: "Kardian", link: "/brand/kardian" },
  { name: "EarthQuaker Devices", link: "/brand/earthquaker-devices" },
].sort((a, b) => {
  // Convert names to lowercase for case-insensitive sorting
  let nameA = a.name.toLowerCase();
  let nameB = b.name.toLowerCase();
  
  if (nameA < nameB) {
    return -1; // Name A comes before name B
  }
  if (nameA > nameB) {
    return 1; // Name A comes after name B
  }
  return 0; // Names are equal
});
;

const navItems = [
  {
    name: "Home",
    link: "/",
  },
  { name: "About", link: "/about" },
  { name: "Brand", link: "/brand" },
  { name: "Contact us", link: "/contact-us" },
];

const getSubmenuMob = () => {
  return (
    <List style={{ marginLeft: 70 }}>
      {Brands.map(({ name, link }) => (
        <ListItem key={name} disablePadding>
          <Link
            style={{
              width: "100%",
              textDecoration: "none",
              textAlign: "center",
              color: "#2596be",
              textTransform: "uppercase",
            }}
            to={link}
          >
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText primary={name} />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </List>
  );
};
function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (link) => {
    console.log(link);
    if (typeof link === "string") navigate(link);
    setAnchorEl(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ py: 0.5, background: "#2596be" }}>
        <img height={40} src={Logo} alt="TYT Music" />
      </Typography>
      <Divider />
      <List style={{ paddingTop: "20%" }}>
        {navItems.map(({ name, link }) => (
          <ListItem key={name} disablePadding>
            {name === "Brand" ? (
              <Box
                style={{
                  width: "100%",
                  textDecoration: "none",
                  color: "#2596be",
                  textTransform: "uppercase",
                  
                }}
                
              >
                <Box>
                <ListItemText sx={{padding:'8px 16px',marginLeft:'50px'}} primary={name} />
                  {getSubmenuMob()}
                </Box>
              </Box>
            ) : (
              <Link
                style={{
                  width: "100%",
                  textDecoration: "none",
                  color: "#2596be",
                  textTransform: "uppercase",
                }}
                to={link}
              >
                <ListItemButton >
                  <ListItemText style={{width:'100%',marginLeft:50}} primary={name} />
                </ListItemButton>
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar style={{ background: "#2596be" }} component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <Link style={{ textDecoration: "none", color: "#fff" }} to="/">
              <img height="45px" src={Logo} alt="TYT Music" />
            </Link>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button sx={{ color: "#fff" }}>
              <Link style={{ textDecoration: "none", color: "#fff" }} to="/">
                {" "}
                Home
              </Link>
            </Button>
            <Button sx={{ color: "#fff" }}>
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to="/about"
              >
                {" "}
                About Us
              </Link>
            </Button>
            <>
              <Button
                sx={{ color: "#fff" }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                Brand
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {Brands.map(({ name, link }) => (
                  <MenuItem key={name} onClick={() => handleClose(link)}>{name}</MenuItem>
                ))}
              </Menu>
            </>
            <Button sx={{ color: "#fff" }}>
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to="/contact-us"
              >
                Contact Us
              </Link>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
export default Navbar;
