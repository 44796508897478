import { Typography } from "@mui/material";

const bergantinoAudioContent = {
  title: "Bergantino Audio",
  paragraph: [
    <>
      <Typography fontWeight='bold' my={'5px'}>
        Welcome to Bergantino Audio: Elevating Your Musical Experience
      </Typography>      
        We are proud to introduce Bergantino Audio, a brand synonymous with
        excellence in the world of musical amplification. As a trusted
        distributor in the world of Chinese musical instrument distribution, we
        are excited to bring the exceptional products from Bergantino Audio to
        our customers.
    
      <Typography fontWeight='bold'  my='10px'>About Bergantino Audio:</Typography>
        Bergantino Audio is a name that resonates with quality and innovation in
        the field of bass amplification. With a rich history of crafting
        exceptional amplifiers, cabinets, and speakers, Bergantino Audio has
        earned a well-deserved reputation for pushing the boundaries of sound
        engineering. The brand&#39;s journey is marked by an unwavering
        commitment to delivering impeccable sound quality and unparalleled
        performance for musicians across the globe.
   
      <Typography fontWeight='bold'  my='10px'>The Bergantino Audio Difference:</Typography>
        Bergantino Audio products are designed and built with a meticulous
        attention to detail, setting new industry standards. Whether you&#39;re
        a professional musician, a dedicated enthusiast, or a novice, you&#39;ll
        appreciate the brand&#39;s dedication to enhancing your musical
        experience. Bergantino&#39;s signature sound is clear, powerful, and
        incredibly versatile, making it the ideal choice for bassists and
        musicians seeking uncompromised sound quality.
      <Typography fontWeight='bold'  my='10px'>What We Offer:</Typography>
      
        As the official distributor for Bergantino Audio in China, we are
        excited to offer you a wide range of products, including amplifiers,
        speaker cabinets, and accessories. From the studio to the stage,
        Bergantino Audio&#39;s equipment is trusted by musicians worldwide. When
        you choose Bergantino Audio, you&#39;re investing in equipment that will
        take your performance to the next level.
        <br />
        Explore our selection of Bergantino Audio products, and discover the
        perfect match for your musical journey. Whether you&#39;re a seasoned
        pro or just starting, Bergantino Audio has something exceptional to
        offer you.
      <Typography fontWeight='bold'  my='10px'>
        Experience the Bergantino Audio Advantage:
      </Typography>
        Discover the world of Bergantino Audio and let your music come to life
        with unparalleled clarity and power. Join us in this exciting journey as
        we bring the magic of Bergantino Audio to musicians and enthusiasts
        throughout China. Experience the Bergantino difference for yourself.
        <br />
        Thank you for choosing us as your distributor for Bergantino Audio
        products. We look forward to enhancing your musical journey with the
        best in bass amplification.
      
    </>,
  ],
};

export default bergantinoAudioContent;