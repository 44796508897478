const drScientist  = {
  title: "Dr. Scientist ",
  paragraph: [
    <>
      <b>Introduction</b>:<br />
      Dr. Scientist Pedals is a boutique pedal company based in British
      Columbia, Canada, renowned for its high-quality effects pedals that offer
      a blend of creativity, versatility, and craftsmanship. Founded by Ryan
      Clarke and Tanya Clarke, Dr. Scientist has earned a stellar reputation
      among musicians for its innovative designs and commitment to pushing the
      boundaries of sonic exploration. <br />
      <b>History</b>:<br />
      The story of Dr. Scientist Pedals began in the early 2000s when Ryan
      Clarke, a passionate musician and electronics enthusiast, began
      experimenting with building his own guitar pedals. Drawing inspiration
      from his background in physics and his love for music, Clarke set out to
      create pedals that not only sounded exceptional but also offered unique
      and inspiring sonic possibilities.
      <br />
      In 2003, Ryan Clarke founded Dr. Scientist Pedals, initially as a
      small-scale operation run out of his home workshop in British Columbia.
      With a focus on quality craftsmanship and attention to detail, Dr.
      Scientist quickly gained traction among musicians seeking innovative
      effects pedals that stood out from the crowd.
      <br />
      One of Dr. Scientist&#39;s early breakthrough pedals was the
      &quot;Reverberator,&quot; a versatile reverb pedal that offered a wide
      range of lush, atmospheric tones. The Reverberator garnered praise from
      musicians and critics alike for its exceptional sound quality and
      intuitive controls, establishing Dr. Scientist as a player in the boutique
      pedal market.
      <br />
      As Dr. Scientist&#39;s reputation grew, the company expanded its lineup to
      include a diverse range of effects pedals, including overdrive,
      distortion, delay, modulation, and more. Each pedal was meticulously
      designed and hand-built by Ryan Clarke and his team, using premium
      components and innovative circuitry to ensure top-notch performance.
      <br />
      In addition to its standard lineup, Dr. Scientist also offers limited
      edition pedals and custom builds, allowing musicians to tailor their
      pedals to their specific needs and preferences. This dedication to
      customization has further endeared Dr. Scientist to musicians seeking
      unique and personalized effects pedals.
      <br />
      Today, Dr. Scientist Pedals continues to thrive as a leading name in the
      boutique pedal industry, with a growing roster of artists and a global fan
      base. With its commitment to innovation, quality, and sonic exploration,
      Dr. Scientist remains at the forefront of the pedal market, inspiring
      musicians to unleash their creativity and explore new sonic territories.
    </>,
  ],
};

export default drScientist;
