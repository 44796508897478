import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import AboutContent from "../data/about";
export const About = () => {
  return (
    <Container sx={{marginTop:10}}>
    <Typography variant="h4" 
    fontWeight='800'
    textAlign='center'
    color='#2596be'
    fontFamily="Carter One"
    style={{ textShadow: "1px 1px 2px #eeeeee" }}
    >{AboutContent.title}</Typography>
    <Box sx={{marginTop:3}}></Box>
    {AboutContent.paragraph.map((text,index)=><Typography key={index} lineHeight={2.5} variant="body1">{text}</Typography>)}
    </Container>
  );
};
export default About;
