const allevaCoppoloContent = {
  title: "The Alleva Coppolo Bass",
  paragraph: [
    <>
      The Alleva Coppolo Bass by Jimmy Coppolo is a highly regarded electric
      bass guitar that is handcrafted with the utmost care and attention to
      detail. This high-end bass guitar boasts exceptional build quality, tonal
      versatility, and playability, making it a popular choice among
      professional bassists.
      <br />
      The bass features a unique design that blends classic and modern elements,
      resulting in an instrument that is both visually stunning and sonically
      versatile. The body is crafted from premium tonewoods such as old cut old
      growth ash or alder, and the sugar maple neck features a rosewood or maple
      fingerboard with dot or block inlays. The bass is equipped with
      custom-designed pickups and electronics that allow for a wide range of
      tonal options.
      <br />
      The Alleva Coppolo Bass is known for its exceptional sound quality,
      delivering a rich, warm, and punchy tone that is highly sought after by
      bassists across a variety of genres. The bass is highly versatile and can
      deliver a range of sounds, from vintage to modern and cutting-edge.
      <br />
      In summary, the Alleva Coppolo Bass by Jimmy Coppolo is a handcrafted
      masterpiece that combines exceptional craftsmanship, innovative design,
      and superior sound quality. Whether you&#39;re a professional musician or
      a collector, the Alleva Coppolo Bass is an instrument that is sure to
      impress.
    </>,
  ],
};

export default allevaCoppoloContent;
