const IdiotBox = {
  title: "IdiotBox Effects",
  paragraph: [
    <>
      <b>Introduction</b>:<br />
      IdiotBox Effects is a pioneering boutique guitar pedal company, born from
      the creative genius of Atarimatt, and based in College Station , Texas. With a
      commitment to bold experimentation and fearless innovation, IdiotBox has
      carved out a unique niche in the world of effects pedals. From blistering
      distortions to mind-bending modulations, IdiotBox pedals are renowned for
      their distinct character and uncompromising sound quality. <br />
      <b>History</b>:<br />
      The journey of IdiotBox Effects began when Atarimatt, a musician and
      electronics enthusiast, decided to fuse his passion for music with his
      love for tinkering with circuits. In the early days, Atarimatt&#39;s
      workshop in College Station ,Texas , served as the breeding ground for his sonic
      experiments. Drawing inspiration from the DIY ethos of the Texas music
      scene, Atarimatt set out to create pedals that pushed the boundaries of
      conventional guitar effects.
      <br />
      In 2011, Atarimatt officially launched IdiotBox Effects, marking the
      beginning of a new chapter in the world of boutique pedals. The brand
      quickly gained attention for its unconventional designs, rugged build
      quality, and uncompromising commitment to sonic exploration. Each IdiotBox
      pedal was handcrafted with precision and care, using high- quality
      components and innovative circuitry to deliver unparalleled performance.{" "}
      <br />
      One of IdiotBox&#39;s early triumphs was the &quot;Blower Box,&quot; a
      ferocious distortion pedal that captured the raw aggression of classic
      high-gain amps. With its searing tones and responsive controls, the Blower
      Box became a favorite among guitarists seeking to unleash sonic mayhem on
      stage and in the studio. 
      <br />
      As word spread about the sonic prowess of IdiotBox Effects pedals, the
      brand expanded its lineup to include a diverse range of effects, from fuzz
      and overdrive to delay and modulation. Each new release showcased
      Atarimatt&#39;s fearless creativity and commitment to pushing the envelope
      of what was possible with guitar pedals. 
      <br />
      Today, IdiotBox Effects continues to thrive as a beacon of innovation in
      the crowded world of effects pedals. With a growing roster of artists and
      a reputation for quality and creativity, the brand remains at the
      forefront of the boutique pedal industry. As Atarimatt and his team
      continue to push the boundaries of pedal design and sound, IdiotBox
      Effects stands as a testament to the power of fearless experimentation and
      the enduring spirit of sonic exploration.
     <br /> 
      For more information about IdiotBox Effects and its lineup of pedals,
      visit their website at {" "}
      <a href="http://www.idiotboxeffects.com">www.idiotboxeffects.com/</a>.
    </>,
  ],
};

export default IdiotBox;
