const fodera = {
  title: "Blammo Electronics",
  paragraph: [
    <>
      Blammo Electronics stands as a testament to the boundless creativity and
      ingenuity of its founder, Dan, a solitary craftsman in the bustling city
      of Portland, Oregon. With a singular vision and unwavering dedication, Dan
      has transformed his passion for music and electronics into a thriving
      boutique pedal business that has captured the hearts of musicians
      worldwide. <br />
      As a one-man operation, Dan&#39;s journey with Blammo Electronics is a
      story of individual determination and unwavering commitment to quality.
      From the humble confines of his workshop in Portland, Dan meticulously
      designs and handcrafts each pedal, infusing them with his unique blend of
      creativity and precision craftsmanship.
    </>,
  ],
};

export default fodera;
