const alembicGuitarsContent = {
    title: "Alembic Guitars",
    paragraph: [
      <>
      Alembic guitars and basses represent a pinnacle of luthiery, combining artistry and
cutting-edge technology to create instruments that are coveted by musicians across
various genres. Let&#39;s delve into more detail:<br/>
<b>1. Innovative History:</b> Alembic was founded in 1969 by Ron and Susan Wickersham
and quickly gained recognition for its pioneering work in electric instrument design. The
company played a crucial role in the development of electric basses, particularly with its
work on the Grateful Dead&#39;s Phil Lesh&#39;s custom bass guitars, which featured advanced
electronics and sustain-enhancing designs.<br/>
<b>2. Custom Craftsmanship:</b> One of the hallmarks of Alembic instruments is their
bespoke craftsmanship. Each guitar and bass is meticulously handcrafted by skilled
artisans, with an emphasis on using top-quality woods, including exotic and figured
options. These instruments are often customized to the player&#39;s exact specifications, from
the choice of woods to the shape of the body and neck.<br/>
<b>3. Unique Electronics:</b> Alembic instruments are renowned for their advanced
electronics. They often feature active pickups, onboard preamps, and EQ systems,
allowing musicians to shape their sound with unparalleled precision. These electronics
contribute to a wide range of tonal possibilities, making Alembic instruments incredibly
versatile.<br/>
<b>4. Iconic Design:</b> Alembic&#39;s design aesthetics are unmistakable. Their instruments often
feature distinctive body shapes, elegant inlays, and intricate detailing. The company&#39;s
commitment to artistic expression is evident in the exquisite woodwork, intricate inlays,
and custom finishes that adorn each instrument.<br/>
<b>5. Versatility:</b> Alembic guitars and basses are prized for their ability to excel in various
musical genres. While they have a strong presence in the realms of jazz, fusion, and
progressive rock, they have also found favor among funk, metal, and experimental
musicians. The tonal range and playability of Alembic instruments make them suitable
for a wide array of playing styles.<br/>
<b>6. Collector&#39;s Items:</b> Due to their limited production numbers and reputation for quality,
Alembic instruments are often considered collector&#39;s items. Vintage Alembic guitars and
basses are highly sought after by collectors and enthusiasts, with some models
commanding significant prices in the vintage market.<br/>
In summary, Alembic guitars and basses represent the pinnacle of custom craftsmanship,
innovative electronics, and artistic design. These instruments have left an indelible mark
on the music industry, and their unique blend of tradition and innovation continues to
inspire musicians and collectors around the world.
      </>
    ],
  };
  
  export default alembicGuitarsContent;