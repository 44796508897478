const pensa = {
  title: "Pensa",
  paragraph: [
    <>
      Pensa Custom Guitars is a legendary guitar workshop based in New York
      City, known for its custom-made guitars tailored to the preferences of
      professional musicians. The history of Pensa guitars is closely
      intertwined with the story of its founder, Rudy Pensa, and his
      collaboration with master luthier John Suhr.
      <br />
      Rudy Pensa, originally from Argentina, moved to New York City in the late
      1970s with a passion for guitars and a dream of establishing his own
      guitar business. In 1979, Rudy opened Rudy’s Music Stop, a guitar shop
      located in Manhattan’s SoHo district. Rudy&#39;s Music Stop quickly became
      a hub for musicians, attracting a diverse clientele ranging from amateurs
      to seasoned professionals.
      <br />
      It was during this time that Rudy Pensa began collaborating with John
      Suhr, a highly skilled luthier who had previously worked at the iconic
      guitar company, Fender. Together, Rudy and John shared a vision of
      creating custom guitars that offered unparalleled quality, tone, and
      playability.
      <br />
      In the early 1980s, Pensa and Suhr officially established Pensa Suhr
      Guitars, later known simply as Pensa Custom Guitars. The workshop gained
      recognition for its meticulous craftsmanship and attention to detail,
      attracting a roster of high-profile clients, including Mark Knopfler of
      Dire Straits, who became one of Pensa&#39;s most notable endorsers.
      <br />
      One of the most iconic models to come out of the Pensa workshop is the
      Pensa MK, a custom-designed guitar created specifically for Mark Knopfler.
      The Pensa MK featured a sleek, ergonomic design, premium woods, and custom
      electronics, reflecting Knopfler&#39;s preferences for a versatile and
      responsive instrument.
      <br />
      Throughout the 1980s and 1990s, Pensa Custom Guitars continued to thrive,
      producing custom instruments for a wide range of artists across various
      genres. The workshop earned a reputation for its exceptional
      craftsmanship, personalized service, and commitment to quality.
      <br />
      In the early 2000s, John Suhr left Pensa Guitars to focus on his own
      company, Suhr Guitars, while Rudy Pensa continued to run the workshop
      under the Pensa Custom Guitars name. Despite the change, Pensa Guitars
      remained dedicated to producing high- quality custom instruments,
      maintaining its status as one of the premier guitar workshops in New York
      City.
      <br />
      Today, Pensa Custom Guitars continues to operate in New York City,
      offering custom- built guitars and personalized service to musicians
      around the world. With its rich history, dedication to craftsmanship, and
      commitment to excellence, Pensa Guitars remains a beloved institution in
      the guitar community, embodying the spirit of innovation and creativity
      that defines the New York City music scene.
    </>,
  ],
};

export default pensa;
