const AboutContent = {
  title: "About TYT",
  paragraph: [
    "TYT Music: Bringing Boutique Instruments to China",
    "TYT Music, founded in 2019, is a leading distributor of boutique guitar and bass guitar accessories in China. With years of experience in both wholesale and retail, the company has built a reputation for quality products and exceptional customer service.",
    "At TYT Music, we specialize in distributing boutique instruments, such as the Zon bass guitar, La Bella strings, Olinto by La Bella basses, and LK guitar straps. These brands are known for their high-quality materials and craftsmanship, which produce exceptional sound and durability.",
    "We take great pride in the relationships we have built with these brands, as well as our customers in China. By offering a wide range of products, we are able to meet the needs of both beginners and professionals, whether they are looking for a new guitar or simply need replacement strings.",
    "Our team at TYT Music is dedicated to providing the best customer service possible. We are passionate about music and love to share our knowledge and expertise with our customers. Whether you are a seasoned musician or just starting out, we are here to help you find the perfect instrument and accessories to suit your needs.",
    "We believe that music has the power to connect people, inspire creativity, and bring joy to our lives. That's why we are committed to bringing the best products and services to our customers in China. At TYT Music, we strive to create a welcoming community of musicians and music lovers, and we look forward to continuing to serve our customers for years to come.",
    "If you are looking for high-quality guitar and bass guitar accessories in China, look no further than TYT Music. Contact us today to learn more about our products and services.",
  ],
};

export default AboutContent;