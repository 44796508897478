import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/material/styles';
import Logo from '../../assets/logo/logo.png'
const FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(6),
}));

const FooterIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const openInsta=()=>{
  window.open('https://www.instagram.com/tyt_music_cn/','_blank')
}

const Footer = () => {
  return (
    <FooterContainer style={{background:'#2596be'}}>
      <Container maxWidth="lg">
        <Typography fontWeight='800' variant="h6" color='#fff' align="center" gutterBottom>
         <img height='60' src={Logo} alt='TYT Music'/>
        </Typography>
        <Typography  variant="subtitle1" align="center" color='#fff' component="p">
          Copyright © {new Date().getFullYear()}
        </Typography>
        <Box display="flex" justifyContent="center">
          {/* <FooterIconButton aria-label="Facebook">
            <FacebookIcon   sx={{color:'#fff'}}/>
          </FooterIconButton> */}
          {/* <FooterIconButton aria-label="Twitter">
            <TwitterIcon sx={{color:'#fff'}}/>
          </FooterIconButton> */}
          <FooterIconButton onClick={openInsta} aria-label="Instagram">
            <InstagramIcon   sx={{color:'#fff'}} />
          </FooterIconButton>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
