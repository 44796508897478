
const lehGuitarsContent = {
    title: "LEH Guitars",
    paragraph: [
      <>
      LEH Guitars is a boutique workshop that specializes in the creation of high-quality,
custom bass guitars. Based in Portland, Oregon, the company was founded by luthier L.
Ellis Hahn, who brings over two decades of experience in guitar building to the table.<br/>
What sets LEH Guitars apart is its commitment to providing personalized, tailored
services to each individual customer. The company takes great care to understand the
needs and preferences of each player, and creates instruments that are not only visually
stunning, but also comfortable and intuitive to play.<br/>
The Offset Bass is one of LEH Guitars&#39; most popular models. This unique instrument
features an offset body design that balances comfort with playability, while the use of
premium tonewoods and top-quality hardware and electronics ensures exceptional tone
and durability.<br/>
LEH Guitars also prides itself on using only sustainable, ethically-sourced materials,
minimizing its environmental impact while producing instruments of the highest quality.
Each bass guitar is crafted with meticulous attention to detail, resulting in an instrument
that not only looks and sounds great, but will stand the test of time.<br/>
With its focus on custom craftsmanship and personalized service, LEH Guitars has
earned a reputation as one of the premier bass guitar builders in the industry. Whether
you&#39;re a professional musician seeking a one-of-a-kind instrument, or a hobbyist looking
for an exceptional bass guitar that truly fits your style, LEH Guitars is a company that is
sure to exceed your expectations.
      </>
    ],
};
  
export default lehGuitarsContent;