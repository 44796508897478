import { Container, Link, Typography } from "@mui/material";
export const Contact = () => {
  return (
    <Container style={{marginTop: 40, minHeight: "60vh" }}>
      <Typography
        variant="h4"
        fontWeight="800"
        textAlign="center"
        color="#2596be"
        fontFamily="Carter One"
        style={{ textShadow: "1px 1px 2px #eeeeee" ,marginBottom:50}}
      >
        Contact us
      </Typography>

      <Typography textAlign='center' lineHeight={2.5} fontWeight="bold" variant="body1">
      If you have any questions
      or would like to schedule an appointment, please don't hesitate to contact us. 
      </Typography>
      <Typography  textAlign='center' lineHeight={2.5} fontWeight="bold" variant="body1">
        Phone: <Link href="tel:+8615906182663">+86 15906182663</Link>
      </Typography>

      <Typography textAlign='center' fontWeight="bold" lineHeight={2.5} variant="body1">
        Email: <Link href="mailto:tytmusic@163.com">tytmusic@163.com</Link>
      </Typography>
       <Typography textAlign='center' fontWeight="bold" lineHeight={2.5} variant="body1">OR</Typography>
       <Typography textAlign='center' fontWeight="bold" lineHeight={2.5} variant="body1">
       Email: <Link href="mailto:info@tyt-music.com">info@tyt-music.com</Link>
      </Typography>
    </Container>
  );
};
export default Contact;
